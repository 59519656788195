import {Component, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ColumnMode} from "@swimlane/ngx-datatable";
import {logger} from "codelyzer/util/logger";

import {RestService} from "../../services/rest.service";
import {RegistrantGlimpse} from "../../interfaces/registrant-glimpse";
import {ModalController, ToastController} from "@ionic/angular";
import {SummonComponent} from "../../models/summon/summon.component";
import {AttendeeDetailComponent} from "../../models/attendee-detail/attendee-detail.component";
import {environment} from "../../../environments/environment";
import {StorageService} from "../../services/storage.service";
import {REG_STATION_NUMBER_KEY} from "../../utils/constants";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-attendees',
  templateUrl: './attendees.component.html',
  styleUrls: ['./attendees.component.scss'],
})
export class AttendeesComponent implements OnInit {
  columns = [
    { name: 'First Name', prop: 'conFirstName' },
    { name: 'Last Name', prop: 'conLastName' },
    { name: 'E-mail', prop: 'conEmail' },
    { name: 'DOB', prop: 'conDOB' },
    { name: 'Badge Name', prop: 'conBadgeName' },
    { name: 'Reg Level', prop: 'conRegLevel' },
    { name: 'Checked In', prop: 'conCheckedIn' }
  ];

  @ViewChild('myTable') table: any;

  regGlimpse: RegistrantGlimpse[] = [];
  loadingIndicator = true;

  constructor(
    private rest: RestService,
    public toastController: ToastController,
    public modalController: ModalController,
    private router: ActivatedRoute,
    private rtr: Router,
    private storage: StorageService,
    private auth: AuthService
  ) {

  }

  ColumnMode = ColumnMode;
  reorderable = true;

  param = "";

  getList(filter: string = ""){
    this.rest.getResults("registrants-glimpse/?"+filter+'&event='+environment.eventPk)
      .subscribe(
        (res) => {
          console.log(res);
          this.temp = [...res]
          this.regGlimpse = res;
          this.loadingIndicator = false;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  ngOnInit() {
    if(this.auth.loginChecked && !this.auth.loggedIn) {
      this.rtr.navigate(['/login']);
    }
    this.param = "";

    let filter = this.router.params.subscribe(
      (res) => {
        if(res.param === "conCreepFlag"){
          console.log(res.param);
          this.param = "Creep Flag";
          this.getList(res.param+"=true");
        }else if(res.param === "conCheckedIn") {
          console.log(res.param);
          this.param = "Checked In";
          this.getList(res.param + "=true");
        }else if(res.param === "accessibility") {
          console.log(res.param);
          this.param = "Enrolled in Accessibility";
          this.getList(res.param + "=true");
        }else if(res.param === "conIsDealer") {
          console.log(res.param);
          this.param = "Registered Dealers";
          this.getList(res.param + "=true");
        }else if(res.param === "conIsDealerAssistant") {
          console.log(res.param);
          this.param = "Dealers Assistants";
          this.getList(res.param + "=true");
        }else if(res.param === "conIsStaff") {
          console.log(res.param);
          this.param = "Staff Members";
          this.getList(res.param + "=true");
        }else if(res.param === "conCheckedIn,hasMealPlan"){
          this.param = "Has Meal Plan & Is Checked In"
          this.getList("conCheckedIn=true&hasMealPlan=true");
        }else if(res.param === "isForwarding"){
          this.param = "Has been Forwarded to Next Year"
          this.getList("isForwarding=true");
        }else if(res.param === "isAttendee"){
          this.param = "Attendees"
          this.getList("conRegLevel=5c5333fa-38fd-43e1-9f0c-b27361686963");
        }else if(res.param === "isSponsor"){
          this.param = "Sponsors"
          this.getList("conRegLevel=7dd80cb7-fd3e-47cf-9df5-397e2277fa8b");
        }else if(res.param === "isSuperSponsor"){
          this.param = "Super Sponsors"
          this.getList("conRegLevel=f3a7e901-f53a-4f73-826b-409d1a89d0cd");
        }else if(res.param === "isEliteSponsor"){
          this.param = "Elite Sponsors"
          this.getList("conRegLevel=c11c9b78-70ec-4d23-903f-1a37267897ca");
        }else{
          this.getList();
        }

      },
      (err) => {
        console.log(err);
      }
    )


  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  async launchRegModal(pk: string) {
    let regStationNumber = await this.storage.get(REG_STATION_NUMBER_KEY);

    const modal = await this.modalController.create({
      component: AttendeeDetailComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        "attendeeId": pk,
        "regStationNumber": regStationNumber,
      }
    });
    return await modal.present();
  }

  temp = [];

  updateFilter(event) {
    const val = (event as HTMLInputElement).value.toLowerCase();

    const count = this.columns.length;
    // get the key names of each column in the dataset
    const keys = Object.keys(this.temp[0]);
    // assign filtered matches to the active datatable
    this.regGlimpse = this.temp.filter(item => {
      // iterate through each row's column data
      for (let i = 0; i < count; i++) {
        // check for a match
        if (
          (item[keys[i]] &&
            item[keys[i]]
              .toString()
              .toLowerCase()
              .indexOf(val) !== -1) ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }
      }
    });

    // Whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
  }

  reloadSystem() {
    this.presentToast("Please wait, we're fetching from the server.")
    this.regGlimpse = [];
    this.rest.reloadSystem();
    this.getList();
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  segmentChanged($event: CustomEvent) {
    console.log($event);
  }
}
